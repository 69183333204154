import React from 'react'
import '../../../styles/BlogDetails.css'
// import ShareButton from '../../General_cmpnts/ShareButton/ShareButton';
// import RelatedPosts from '../RelatedPosts/RelatedPosts';
function BlogDetails({ blogs }) {
  if (!blogs || blogs.length === 0) {
    return <div className="p-skeleton-loading">
      <div className="p-loading">
        <div className="line"></div>
        <div className="line"></div>
        <div className="line"></div>
      </div>
      <div className="headline_loading"></div>

    </div>;
  }

  return (
    <div className='BlogDetails'>
      {blogs && (
        <div className="container">
          {blogs && <div dangerouslySetInnerHTML={{ __html: blogs.content.rendered }}></div>}
          {/* <RelatedPosts /> */}
        

        </div>
      )}

    </div>

  );
}

export default BlogDetails